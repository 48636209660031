import Web3 from "web3"
import BigNumber from "bignumber.js";

export const fromUnit = (wei, decimal = 18) => {
    if (wei <= 0) {
      return 0
    }
    let weiwei = Number(toFixed(wei, 0)) || 0
    return (Web3.utils.fromWei((numberToStr(weiwei) || 0).toString(), decimal === 18 ? "ether" : "mwei"));
  };
  const numberToStr = (num = 0) => {
    let splits = num.toString().toLowerCase().split("e+");
    let result = splits[0];
    if (splits.length === 2) {
      result = result * Math.pow(10, parseInt(splits[1]));
    }
    return result.toLocaleString("fullwide", {
      useGrouping: false,
    });
  };
  const howManyZero = (num) => {
    if (num > 1) {
      return 0
    } else {
      let zeronum = 0
      for (let i = 0; i <= 18; i++) {
        if (Number(num) >= Number(Math.pow(10, 0 - i))) {
          zeronum = i
          break;
        }
      }
      return zeronum - 1
    }
  }
  export const toFixed = (amount, num) => {
    if (Number(amount) < 1) {
      num <= howManyZero(amount) && (num = howManyZero(amount) + num)
    }
    return new BigNumber(
      Math.floor(Number(amount) * Math.pow(10, num)) / Math.pow(10, num)
    ).toString(10);
  };
export const addPoint = (address, len = 5) => {
    return address ? address.substr(0, len) + '...' + address.substr(address.length - len,) : ''
}
export const numFormat = function (num) {
    num = num.toString().split(".");  // 分隔小数点
    var arr = num[0].split("").reverse();  // 转换成字符数组并且倒序排列
    var res = [];
    for (var i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
            res.push(",");   // 添加分隔符
        }
        res.push(arr[i]);
    }
    res.reverse(); // 再次倒序成为正确的顺序

    if (num[1]) {  // 如果有小数的话添加小数部分
        return res.join("").concat("." + num[1]);
    } else {
        return res.join("");
    }
}
export const onCopyToText = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    // message.success(t('Copied'))
  };
  export const web3_provider = new Web3.providers.HttpProvider('https://eth.llamarpc.com')

  export const getBalanceETH = (user) => {
    const web3 = new Web3(web3_provider)
    return web3.eth.getBalance(user)
  }

  export const isAddress = (user) => {
    return Web3.utils.isAddress(user)
  }