import sonc from './images/sonc.png'
import logo from './images/logo.svg'
import bg from './images/bg.png'
import textbg from './images/text.png'
import people from './images/people.png'
import submit from './images/submit.svg'
import inputbg from './images/input.png'
import tip from './images/tip.png'
import twitter from './images/twitter.svg'
import './home.scss'
import { useCallback, useEffect, useState } from 'react'
import Connect from './Connect'
import { cryptoData, post } from '../http'
import { useSnackbar } from 'notistack'
import Modal from '../components/Modal'
import { Vertify } from '@alex_xu/react-slider-vertify'
import about from '../assets/image/about.jpg'
import { fromUnit, getBalanceETH, isAddress, toFixed } from '../lib/util'

export default () => {
    const [account, setAccount] = useState('')
    const [address, setAddress] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  let [showWhite, setShowWhite] = useState(false)
  let [loading, setLoading] = useState(false)
  let [isRightAddress, setRightAddress] = useState(true)
  let [bal, setBal] = useState(0)
 
    const toSubmit = useCallback(()=>{
            // enqueueSnackbar(("Submit Paused"), {
            //   variant: 'warning',
            //   autoHideDuration: 2000,
            //   anchorOrigin: {
            //     vertical: 'top',
            //     horizontal: 'right',
            //   },
            // })
            // return
            if(!address) {
                enqueueSnackbar(("Please input your ETH wallet first"), {
                    variant: 'warning',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  })
                  return
            } else {
                setLoading(true)
                post('/api/ido/user/register'+cryptoData('/api/ido/user/register', {
                    address
                }),{
                    address
                }).then(res => {
                   enqueueSnackbar(("Submit success"), {
                    variant: 'success',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  })
                  setLoading(false)
                }).catch (err => {
                    setLoading(false)
                    enqueueSnackbar(("Submit failed:"+err.msg||'please try again'), {
                    variant: 'error',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  })
                })
            }
    }, [address])

    useEffect(
      ()=>{
        (async ()=>{
          setRightAddress(isAddress(address))
          if(isAddress(address)) {
            let balance = await getBalanceETH(address)
            setBal(balance)
          } else {
            setBal(0)
          }
        })()
      }
      , [address])

    return (
        <div className='w100 homepage p-t-30 p-l-60 p-r-60 p-b-80' style={{'zoom':window.innerWidth/1728}}>
            {/* <img src={soncbg} alt="" className='w100'/> */}
            <div className="w100 flex flex-center flex-between header">
            <img src={logo} alt="" className='w-250' />
            <div className="flex flex-center">
                    {/* <div className="lh-40 h-40 m-r-15 fz-20 underline mint-btn pointer" onClick={()=>{
                      setShowWhite(true)
                    }}>
                    Fair-launch whitelist
                    </div> */}
                    <a href="https://fairlaunch.sonc.la" className='mint-btn' target="_blank">
                    <div className=" w-150 lh-40 h-40 m-r-20 fz-20">
                    Fair-launch
                    </div>
                  </a>
            <a href="https://mint.sonc.la" className='mint-btn' target="_blank">
                    <div className=" w-150 lh-40 h-40 m-r-20 fz-20">
                    Now Mint
                    </div>
                  </a>
            <Connect accountChange={(n)=>setAccount(n)}/>
            </div>
            </div>

            <div className="dance-show p-t-50 p-l-30">
                <img src={bg} alt="" className='w100' />
                <div className="text-area">
                  <img src={textbg} alt="" className='text-bg w-400' />
                  <div className="text-content w-350 fwb fz-20">
                  SONC is a next-generation decentralized social network built on the BitCoin Lightning Network based on the Taproot Assets & Nostr protocol.
                  </div>
                </div>
                <img src={people} alt="" className='w-727 people'/>
                <img src={sonc} alt="" className='w-1000 sonc'/>
{/* <input disabled value={account} type="text" className='input-bar fz-22 w-980 p-l-20' style={{fontFamily: 'Autour_One'}} placeholder='Please connect your BTC wallet (starting with bc1p)'/>
                    <img src={submit} alt="" className='pointer w-240' onClick={toSubmit} /> */}
                <div className="input-area flex flex-center gap-10 flex-middle w100">
                    
                    
                </div>
                {/* <div className="flex flex-last tips-area">
                <div className="tips cf flex flex-center p-l-20 gap-15 p-r-20">
                    <img src={tip} alt="" className='w-24 h-24'/>
                    SONC has not yet released any Token, NFT, or inscription assets
                </div>
            </div> */}
            </div>
            <div className="flex flex-between p-t-50 w100 flex-center p-r-45">
                    
                <span className="right-reserve fz-18 fwb">
                ©2023 SONC.LA - All Rights Reserved.
                </span>
                <a className='com-link w-26' href="https://twitter.com/sonc_la" target='_blank'>
                <img src={twitter} alt="" className='w-26 h-20' />
                </a>
            </div>

            <Modal title="Slide fill puzzle right" isOpen={showWhite} onClose={()=>setShowWhite(false)}>
              {/* <Vertify
                width={320}
                height={160}
                imgUrl={about}
                visible={true}
                text="Slide fill puzzle right"
                onSuccess={() => {toSubmit();setSliderVisible(false)}}
                onFail={() => {document.getElementsByClassName('loadingContainer')[0].getElementsByTagName('span')[0].innerHTML = 'Loading'}}
                onRefresh={() => {document.getElementsByClassName('loadingContainer')[0].getElementsByTagName('span')[0].innerHTML = 'Loading'}}
              /> */}
              <div className="ta fz-18 fwb">SONC token Fair-Launch Whitelist application</div>
              <input type="text" placeholder='Please connect your eth mainnet wallet' className='submit-input lh-59 h-58 ta fz-16  ii' onChange={(e)=>setAddress(e.target.value)}/>
              <span className='w100 ta fz-12 cr m-t-4'>{(address && !isRightAddress)?'wrong address':(address ? `Balance:${(toFixed(fromUnit(bal), 6))}ETH`:'') }</span>
              <div className={'submit-btn w100 h-58 lh-58 ta pointer '+((!address || !isRightAddress || fromUnit(bal)*1 < 0.01)?'disabled':'')} onClick={()=>{
                if((!address || !isRightAddress || fromUnit(bal)*1 < 0.01)) {
                  return
                }
                toSubmit()
              }}>
                {
                  !address ? 'Please input your ETH address':
                  !isRightAddress ? 'Please check your ETH address':
                  (fromUnit(bal)*1 < 0.01) ? 'ETH balance is less than 0.01 ETH':'Submit'
                }
              </div>
              <div className="info-area fz-14 c04 m-t-20">
                  <div className='flex flex-start gap-20'>
                      <span>1</span>
                      <span>If the balance of the EVM wallet that submits the whitelist address is greater than 0.01ETH, we need to verify that you are not a BOT and do not need to submit the wallet address again.</span>
                  </div>
                  <div className='flex flex-start gap-20 m-t-20'>
                      <span>2</span>
                      <span>Please confirm that the address you submitted during Fair-Launch is the same</span>
                  </div>
                  <div className='flex flex-start gap-20 m-t-20'>
                      <span>3</span>
                      <span>Please pay close attention to our Twitter regarding the upcoming progress of Fair Launch and Fair Launch IDO Rules</span>
                  </div>
                  <div className='flex flex-start gap-20 m-t-20'>
                      <span>4</span>
                      <span>The submitted whitelist address does not mean that your quota can be reserved. Participation rules are FCFS.</span>
                  </div>
                  <div className='flex flex-start gap-20 m-t-20'>
                      <span>5</span>
                      <span>Please ensure that your ETH balance is >=0.2 when participating in the fair launch IDO</span>
                  </div>
                  <div className='flex flex-start gap-20 m-t-20'>
                      <span>6</span>
                      <span>Welcome to join the SONC family, let’s get started</span>
                  </div>
              </div>

            </Modal>
            



        </div>
    )
} 