
import { useEffect, useState } from 'react'
import { addPoint, onCopyToText } from '../lib/util'
import people from '../assets/image/people.svg'
import copy from '../assets/image/copy.svg'
import copiedIcon from '../assets/image/copied.svg'
import logout from '../assets/image/logout.svg'
import './home.scss'

const CopyText = ({text, showText}) => {
    const [copied, setCopied] = useState(false)
    return (
        <div onClick={()=>{
            onCopyToText(text)
            setCopied(true)
            setTimeout(()=>{
                setCopied(false)
            }, 3000)
        }} className='flex flex-center gap-5'>
                    <img src={copied ? copiedIcon:copy} alt="" className='pointer'/>
                            {
                                copied &&  showText && <span className='copied fz-12'>
                                Copied
                            </span>
                            }       
        </div>
    )
}
export default ({accountChange}) => {

    const [account, setAccounts] = useState('')
    const [showWallet, setShowWallet] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    const handleAccountsChanged = (_accounts) => {
        console.log(_accounts)
        setAccounts(_accounts[0]);
        setShowWallet(false)
    };
    useEffect(()=>{
        accountChange && accountChange(account)
    }, [account])
    return (
        <div className='wallet'>
            <div className="color-btn pointer m-l-4 flex flex-center fz-14  lh-40 h-40" onClick={async () => {
                if(account) {
                    setShowInfo(!showInfo)
                    return
                }
                if(window.unisat) {
                    const result = await window.unisat.requestAccounts();
                    handleAccountsChanged(result);
                } else {
                    window.open('https://unisat.io/')
                }
            }}>
                {
                    account ? <img src={people} /> : 'Connect Wallet'
                }
            </div>
            {
                showInfo &&  <div className="wallet-info p-16">
                <div className="flex flex-center bb p-b-16">
                    <div className="people-icon flex flex-center flex-middle">
                        <img src={people} alt="" />
                    </div>
                    <div className="flex flex-column flex-start flex-center lh-24 m-l-8">
                        <span>Unisat wallet</span>
                        <span className='fz-16 fwb flex flex-center gap-10' >

                            {addPoint(account)}
                            <CopyText text={account} showText/>
                            
                        </span>
                    </div>
                </div>
                <div className="flex flex-between w100  fz-12 bb p-t-16 p-b-16">
                    <span className='c04'>Ordinals Address</span>
                    <span className='fz-12 fwb flex flex-center c0 gap-10'>
                        {addPoint(account)}
                        <CopyText text={account} showText={false}/>
                    </span>
                </div>
                <div className="flex flex-middle w100 c04 fz-14 ta p-t-16 pointer" onClick={()=>{
                    setAccounts('')
                    setShowInfo(false)
                }}>
                    <span className='disconnect flex flex-center gap-3'>Disconnect <img src={logout} alt="" /></span>
                    
                </div>

            </div>
            }
        </div>
    )
}