import { Modal } from "@mui/material"
import closeIcon from './images/close.svg'
// import closewIcon from './images/closew.svg'
import './Modal.scss'


export default ({ children, isOpen, onClose, hideClose=false, className }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={"modal-inner flex flex-column p-t-24 p-b-24 p-l-20 p-r-20 "+className}>
            {!hideClose && <img src={closeIcon} alt="" className='w-60 close-btn pointer' onClick={onClose} />}
                {children}
            </div>
        </Modal>
    )
}